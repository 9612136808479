'use client';

import type { ReactNode } from 'react';
import { LoggingProvider } from '@/providers/LoggingProvider';
import type { ShoppingCartItems } from '@/models/ShoppingCartItems';
import ShoppingCartProvider from '@/providers/ShoppingCartProvider';
import AnalyticsParamsProvider from '@/providers/AnalyticsParamsProvider';
import MapProvider from '@/providers/MapProvider';
import BreakPointProvider from '@/providers/BreakPointProvider';
import { ShoppingCartItem } from '@/models/ShoppingCartItem';
import { ThemeProvider as CarinaThemeProvider } from '@cvent/carina/components/ThemeProvider/ThemeProvider';
import type { Theme as CarinaV1Theme } from '@cvent/carina/components/ThemeProvider';
import type { MapView, ThirdPartyMapType } from '@/constants/mapConstants';
import { AnalyticsProvider } from '@/providers/AnalyticsProvider';
import type { BaseFactDetails } from '@/analytics/models/cventAnalytics/BaseFact';
import SiteCatalystProvider from '@/providers/SiteCatalystProvider';
import ClientDictionaryProvider from '@/providers/ClientDictionaryProvider';
import type { ClientDictionary } from '@/utils/clientDictionary';
import { LoadingProvider } from '@/providers/LoadingProvider';
import type { RequestCookie } from 'next/dist/compiled/@edge-runtime/cookies';
import { AppFeatureProvider } from '@/providers/AppFeatureProvider';

/**
 * This is the top-level wrapper of various context providers for the application.
 * It is used to wrap the entire application in a single provider, which is then
 * used to provide context to all the components in the application. Remember to lean
 * on using server components when you can instead of state and context values when possible.
 */
export default function Providers({
  children,
  cart,
  googleApiKey,
  bingApiKey,
  analyticsServiceUrl,
  theme,
  mapView,
  bingOrGoogle,
  baseProperties,
  clientDictionary,
  appFeatures,
  mapCookieValue
}: {
  theme: CarinaV1Theme;
  children: ReactNode;
  cart: {
    size: number;
    shoppingCartItems: ShoppingCartItems;
    isLoggedIn: boolean;
    rfpVenueList: Array<ShoppingCartItem>;
  };
  googleApiKey: string;
  bingApiKey: string;
  analyticsServiceUrl: string | undefined;
  mapView: MapView;
  bingOrGoogle: ThirdPartyMapType;
  baseProperties: BaseFactDetails;
  clientDictionary: ClientDictionary;
  appFeatures: Record<string, boolean>;
  mapCookieValue: RequestCookie | undefined;
}) {
  return (
    <LoggingProvider>
      <LoadingProvider>
        <AnalyticsProvider baseProperties={baseProperties} analyticsServiceUrl={analyticsServiceUrl}>
          <AnalyticsParamsProvider>
            <MapProvider
              googleApiKey={googleApiKey}
              bingApiKey={bingApiKey}
              initialMapView={mapView}
              bingOrGoogle={bingOrGoogle}
              mapCookieValue={mapCookieValue}
            >
              <CarinaThemeProvider theme={theme}>
                <BreakPointProvider>
                  <ShoppingCartProvider
                    rfpVenueList={cart.rfpVenueList}
                    maxCapacity={cart.size}
                    items={cart.shoppingCartItems}
                    isLoggedIn={cart.isLoggedIn}
                  >
                    <SiteCatalystProvider>
                      <ClientDictionaryProvider clientDictionary={clientDictionary}>
                        <AppFeatureProvider value={appFeatures}>{children}</AppFeatureProvider>
                      </ClientDictionaryProvider>
                    </SiteCatalystProvider>
                  </ShoppingCartProvider>
                </BreakPointProvider>
              </CarinaThemeProvider>
            </MapProvider>
          </AnalyticsParamsProvider>
        </AnalyticsProvider>
      </LoadingProvider>
    </LoggingProvider>
  );
}
