import type { ResultsPageAnalyticsParams } from '@/models/ResultsPageAnalyticsParams';
import React, { createContext, ReactNode, useMemo, useState } from 'react';

export type AnalyticsParamsContextProps = {
  resultsPageAnalyticsParams: ResultsPageAnalyticsParams;
  setResultsPageAnalyticsParams: (params: ResultsPageAnalyticsParams) => void;
};
export const AnalyticsParamsContext = createContext<AnalyticsParamsContextProps>({
  resultsPageAnalyticsParams: {} as ResultsPageAnalyticsParams,
  setResultsPageAnalyticsParams(): void {}
});

type Props = {
  children: ReactNode;
};

/**
 * Here we store the analytics specific properties that
 * might be required while switching between different pages
 *
 * !!! Please don't exhaust this context with unnecessary     !!!
 * !!! properties that can be directly accessed from the page !!!
 */
export default function AnalyticsParamsProvider({ children }: Props) {
  const [resultsPageAnalyticsParams, setResultsPageAnalyticsParams] = useState<ResultsPageAnalyticsParams>({});

  const value = useMemo(
    () => ({
      resultsPageAnalyticsParams,
      setResultsPageAnalyticsParams
    }),
    [resultsPageAnalyticsParams, setResultsPageAnalyticsParams]
  );
  return <AnalyticsParamsContext.Provider value={value}>{children}</AnalyticsParamsContext.Provider>;
}
